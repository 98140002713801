<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="9">
        <base-text-field
          v-model="search"
          class="mb-6"
          clearable
          flat
          hide-details
          placeholder="Search Assignments"
          search-icon
          solo
          @click:clear="clearText"
          @keydown.enter="getAssignments"
        />
      </v-col>

      <v-col cols="12" md="3">
        <v-btn
          class="py-7"
          color="primary"
          depressed
          width="100%"
          @click="getAssignments"
        >
          <v-icon>mdi-magnify</v-icon> Search
        </v-btn>
      </v-col>

      <v-col cols="0" md="9" />

      <v-col cols="12" md="3">
        <v-select
          v-model="reEvaluationStatus"
          class="header-fields"
          :disabled="loading"
          flat
          hide-details
          item-text="name"
          item-value="value"
          :items="reEvaluationStatusChoices"
          label="Status"
          solo
        />
      </v-col>

      <v-col cols="12">
        <base-data-table
          class="mt-2"
          :headers="headers"
          :items="items"
          :items-per-page="params.itemsPerPage"
          :loading="loading"
          :page="params.page"
        >
          <template v-slot:[`item.assignment`]="{ item }">
            {{
              item.assignment.title
                | formatAssignmentTitle(item.assignment.short_title)
            }}
          </template>

          <template v-slot:[`item.assignment.courses`]="{ item }">
            {{ item.assignment.courses | formatCourses }}
          </template>

          <template v-slot:[`item.due_date`]="{ item }">
            <span
              v-if="item.due_date"
              :class="{
                'red--text':
                  !IsEvaluationCompleted(item.evaluation_status) &&
                  moment() > moment(item.due_date),
              }"
            >
              {{ moment(item.due_date).format("LL") }}
            </span>
            <span v-else>
              -
            </span>
          </template>

          <template v-slot:[`item.evaluation_status_display`]="{ item }">
            <span :class="item.evaluation_status_display.color">
              {{ item.evaluation_status_display.text }}
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              class="pr-0 pr-md-2"
              color="primary"
              :disabled="!item.evaluated_submission"
              small
              text
              :to="getSubmissionRoute(item.evaluated_submission)"
            >
              VIEW SUBMISSION
            </v-btn>
          </template>

          <template v-slot:footer>
            <base-paginator
              :items-per-page="params.itemsPerPage"
              :page="params.page"
              :total="total"
              @change="params.page = $event"
            />
          </template>
        </base-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import urls from "@/utils/urls"
import filters from "@/utils/mixins/filters"
import { EVALUATION_STATUS_FOR_ASSIGNMENT_USERS } from "@/utils/constants"

export default {
  name: "ReEvaluation",

  mixins: [filters],

  data: () => ({
    search: "",

    reEvaluationStatus: null,

    items: [],
    total: 0,
    loading: false,

    params: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },

    reEvaluationStatusChoices: [
      { name: "All", value: null },
      {
        name: "Under Evaluation",
        value: EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.UNDER_EVALUATION,
      },
      {
        name: "Changes Requested",
        value: EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.CHANGES_REQUESTED,
      },
      {
        name: "Challenged",
        value: EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.CHALLENGED,
      },
      {
        name: "Completed",
        value: EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.COMPLETED,
      },
    ],
  }),

  computed: {
    headers() {
      /**
       * Header passing depends on re-evaluation status, therefore computed property
       */
      const headers = [
        {
          text: "Name",
          sortable: false,
          value: "user.full_name",
        },
        {
          text: "Assignment Name",
          sortable: false,
          value: "assignment",
        },
        {
          text: "Courses",
          sortable: false,
          value: "assignment.courses",
        },
        {
          text: "Status",
          sortable: false,
          value: "evaluation_status_display",
        },
        {
          text: "Challenge Status",
          sortable: false,
          value: "challenge_status",
        },
        {
          text: "Due Date",
          sortable: false,
          value: "due_date",
        },
        {
          text: "",
          sortable: false,
          value: "action",
          align: "end",
        },
      ]

      let allowedHeaders = []

      if (
        this.reEvaluationStatus ===
        EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.CHALLENGED
      ) {
        allowedHeaders = [
          "user.full_name",
          "assignment",
          "assignment.courses",
          "action",
        ]
      } else if (
        this.reEvaluationStatus ===
        EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.UNDER_EVALUATION
      ) {
        allowedHeaders = [
          "user.full_name",
          "assignment",
          "assignment.courses",
          "due_date",
          "action",
        ]
      } else {
        allowedHeaders = [
          "user.full_name",
          "assignment",
          "assignment.courses",
          "evaluation_status_display",
          "due_date",
          "action",
        ]
      }

      return headers.filter(obj => allowedHeaders.includes(obj.value))
    },
  },

  watch: {
    "params.page"() {
      this.getAssignments()
    },
    reEvaluationStatus() {
      this.getAssignments()
    },
  },

  mounted() {
    this.getAssignments()
  },

  methods: {
    getEvaluationStatusDisplay(status) {
      switch (status) {
        case EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.UNDER_EVALUATION:
          return { color: "default", text: "Under Evaluation" }
        case EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.CHANGES_REQUESTED:
          return { color: "orange--text lighten-1", text: "Changes Requested" }
        case EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.CHALLENGED:
          return { color: "red--text", text: "Challenged" }
        case EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.COMPLETED:
          return { color: "green--text", text: "Completed" }
        // case EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.REVIEW_CHANGED:
        //   return { color: "default", text: "Review Changed" }
        default:
          return { color: "default", text: "-" }
      }
    },

    clearText() {
      this.search = ""
      this.getAssignments()
    },

    async getAssignments() {
      this.loading = true

      const params = {
        fields: ["evaluation_status", "due_date"],
        page: this.params.page,
        search: this.search,
      }

      const { COMPLETED, SKIPPED } = EVALUATION_STATUS_FOR_ASSIGNMENT_USERS

      if (this.reEvaluationStatus === COMPLETED) {
        params.evaluation_status__in = `${COMPLETED},${SKIPPED}`
      } else {
        params.evaluation_status = this.reEvaluationStatus
      }

      try {
        const {
          data: { data, total },
        } = await this.$http(urls.assignments.reEvaluation.list, {
          params,
        })

        this.items = data.map(item => ({
          ...item,
          evaluation_status_display: this.getEvaluationStatusDisplay(
            item.evaluation_status
          ),
        }))

        this.total = total
      } catch (err) {
        console.error(err)
        if (err.response) {
          const { errors } = err.response.data
          if (errors.status) this.$toast.error(...errors.status)
        }
      } finally {
        this.loading = false
      }
    },

    getSubmissionRoute(uuid) {
      return uuid
        ? {
            name: "ViewSubmission",
            params: { submissionId: uuid },
          }
        : undefined
    },

    IsEvaluationCompleted(status) {
      return status === EVALUATION_STATUS_FOR_ASSIGNMENT_USERS.COMPLETED
    },
  },
}
</script>
